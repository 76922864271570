import { graphql } from "gatsby"
import * as React from "react"
import FlexibleContentWrapper from "../components/flexible-content-wrapper"
import Hero from "../components/hero"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = ({ data }) => {
  const { title, hero, flexibleContent } = data.craftContactContactEntry
  const { textColour, backgroundColour, image, hideTitle } = hero[0]

  return (
    <Layout>
      <Seo title={title} />

      <Hero
        title={title}
        textColour={textColour}
        backgroundColour={backgroundColour}
        image={image[0]}
        hideTitle={hideTitle}
      />

      <FlexibleContentWrapper flexibleContent={flexibleContent} />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageQuery {
    craftContactContactEntry {
      title
      hero {
        ... on Craft_hero_hero_BlockType {
          textColour
          backgroundColour
          hideTitle
          image {
            ... on Craft_images_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
