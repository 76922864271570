import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Fade } from "react-awesome-reveal"

const Hero = ({
  title,
  backgroundColour = "#FFF",
  textColour = "charcoal",
  image,
  hideTitle,
}) => {
  return (
    <Fade delay={750} triggerOnce>
      <div
        className="aspect-w-16 aspect-h-9"
        style={{ backgroundColor: backgroundColour }}
      >
        {image && (
          <GatsbyImage
            className="object-contain"
            alt=""
            image={getImage(image.localFile)}
            style={{
              position: "absolute", // To override Gatsby Image's 'relative' override
            }}
          />
        )}
        {!hideTitle && (
          <div className="absolute top-0 left-0 flex justify-content items-center">
            <div className="w-full px-4 sm:gutter">
              <h1
                className={`font-heading text-fluid-4xl md:text-4xl xl:text-6xl font-black uppercase text-center text-${textColour}`}
              >
                {title}
              </h1>
            </div>
          </div>
        )}
      </div>
    </Fade>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  backgroundColour: PropTypes.string,
  textColour: PropTypes.string,
  hideTitle: PropTypes.bool,
  image: PropTypes.shape({
    localFile: PropTypes.shape(),
  }),
}

Hero.defaultProps = {}

export default Hero
